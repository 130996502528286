.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: linear-gradient(160deg, rgba(2,0,36,1) 0%, rgba(24,24,105,1) 50%, rgba(2,0,36,1) 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.textArea {
  max-width: 90%;
  color: #ffffff;
}
.textArea:focus {
  border-color: darkorange !important;
}

.chakra-react-select .chakra-react-select__value-container,
.chakra-react-select .chakra-react-select__value-container:hover {
  background: #ffffff;
}

.chakra-react-select .chakra-react-select__control {
  border-color: black;
}

.chakra-react-select .chakra-react-select__option {
  color: black;
}

.chakra-react-select .chakra-react-select__indicator-separator {
  border-color: black;
}